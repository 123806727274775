#claim {
    position: relative;
}

#claim .purpose-body1 {
    padding-top: 9%;
    margin-top: -16%;
    background-image: url(../../assets/images/claim/claim-bg.png);
    background-position: top;
}

#claim .step-content .input-info {
    outline: none;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #c28e10;
    margin: 0 3% 0 0;
    font-size: 35px;
    line-height: 1.9;
    max-width: 350px;
    min-height: 50px;
}

#claim .bottom-bg {
    width: 100%;
}
#claim .failed-msg {
    margin-top: 1%;
    font-size: medium;
}
#claim .connect-btn.disable {
    opacity: 0.6;
    cursor: not-allowed;
}
.eth-symbol {
    font-family: sans-serif!important;
}
#claim .eth-symbol {
    font-size: 40px;
    margin-right: 10px;
}
@media (max-width: 1440px) {
    #claim h2 {
        font-size: 65px;
    }
}


@media (max-width: 992px){
    #claim .purpose-body1 {
        margin-top: -20%;
        padding-top: 15%;
    }
}
@media (max-width: 767px){
    #claim .purpose-body1 {
        margin-top: -25%;
        padding-top: 20%;
    }
    #claim h2 {
        font-size: 55px;
    }
}
@media (max-width: 500px){
    #claim .purpose-body1 {
        margin-top: -30%;
        padding-top: 150px;
    }
}