#verify {
    padding: 1% 0 0 0;
    position: relative;
    z-index: 1;
    background-image: url(../../assets/images/verify/verify-bg.png);
    margin-top: -16%;
    padding-top: 15%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}
.step-item:not(:first-child) {
    margin-top: 10%;
}
.step-title h2{
    position: relative;
    background: linear-gradient(-90deg, #be7111 0%, #be7111 20%, #e4bc67 50%, #c98526 100% );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "ARMY RUST" !important;
    font-size: 75px;
    display: inline-block;
    margin-bottom: 0;
    line-height: 1;
}
.url {
    font-family: 'BebasNeue';
    font-size: 25px;
    background: linear-gradient(-90deg, #be7111 0%, #be7111 20%, #e4bc67 50%, #c98526 100% );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.url.border:hover {
    border-bottom: 2px solid #be7111;
}
.step-content input{
    outline: none;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #fff;
    margin: 0 20px;
    flex: 2;
    font-size: 35px;
    line-height: 1.3;
}
#verify .gradient-btn {
    border-radius: 0px !important;
    margin-top: 0;
    padding: 10px 30px;
}
#verify .gradient-btn[disabled] {
    opacity: .7;
}
#verify .verify-input {
    font-family: 'BebasNeue';
    font-size: 26px;
}
.step-content p {
    font-size: 22px;
}
.step-content .gradient-btn.verify-social {
    font-size: 50px;
    padding: 10px !important;
    color: #000;
}
.step-content .gradient-btn.verify-social.twitter {
    margin-right: 15px;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, #1e64f6, #383fe4 50%, #3089f9 75%, #03a9f4);
}
.step-content .gradient-btn.verify-social.discord {
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, #453cf4, #611df0 50%, #3089f9 75%, #03a9f4);
    padding: 10px 7px !important;
}

#verify h3 {
    font-family: 'BebasNeue';
    font-size: 43px;
}
#verify .blue {
    color: #0f72f5;
}
#verify .tickIcon {
    font-size: 40px;
}
.flex-5 {
    flex: 5;
}
.flex-1 {
    flex: 1;
}
.verify-footer {
    margin-top: -30% !important;
}
#verify .bottom-bg {
    width: 100%;
}
#two_fa input {
    font-size: 20px !important;
}
.PhoneInput {
    margin-left: 2%;
    flex: 10;
}
.PhoneInputCountrySelect option {
    color: #000 !important;
    font-size: 22px;
}
@media (max-width: 1440px) {
    #verify {
        margin-top: -250px;
        padding-top: 250px;
    }
    #verify, .step-content input {
        font-size: 25px;
        letter-spacing: 2px;
    }
    #verify h2 {
        font-size: 65px;
    }
    #verify h3 {
        font-size: 37px;
    }
    .step-content .gradient-btn.verify-social {
        font-size: 30px;
    }
}

@media (max-width: 1100px) {
    .step-content .gradient-btn {
        font-size: 18px;
        padding: 10px 20px;
    }
    #verify, .step-content input {
        font-size: 20px;
        letter-spacing: 0px;
    }
    #verify h3 {
        font-size: 33px !important;
    }
    .step-content .gradient-btn.verify-social {
        font-size: 25px;
    }
    #verify .tickIcon {
        font-size: 35px;
    }
}
@media (max-width: 991px) {
    .step-item:not(:first-child) {
        margin-top: 10%;
    }
    .step-title {
        display: inline-block;
        margin-bottom: 5%;
        height: auto;
        justify-content: start;
    }
    .step-content .gradient-btn.verify-social.twitter {
        margin-right: 5px;
    }
    .step-content .gradient-btn.verify-social {
        font-size: 20px;
    }
    .step-content .verify-social
    .flex-5 {
        flex: 9 1;
    }
    .step-content p {
        font-size: 20px;
    }
    #verify .tickIcon {
        font-size: 35px;
    }
}

@media (max-width: 767px) {
    #verify h2 {
        font-size: 55px;
    }
}

@media (max-width: 576px) {
    #verify, .step-content input {
        font-size: 17px;
        letter-spacing: 2px;
    }
    .step-content p {
        letter-spacing: 1px;
    }
    #verify {
        text-align: center;
    }
    .flex-5 {
        flex: 12 1;
    }
    .step-content .gradient-btn.verify-social.twitter {
        margin-right: 0px;
        margin-bottom: 5px;
    }
    .step-content .gradient-btn.verify-social.discord {
        padding: 10px 7.5px;
    }
    #verify .step-content .gradient-btn {
        font-size: 15px;
        padding: 10px;
    }
    .url {
        font-size: 16px;
        overflow-wrap: anywhere;
        display: inline-block;
        margin-right: 5px;
    }
    .arrow-icon {
        width: 10px;
    }
    .step-content input {
        margin: 0 5px;
    }
    #verify .tickIcon {
        font-size: 30px;
    }
    .step-content p {
        font-size: 18px;
    }
}
@media (max-width: 425px) {
    #header .header-content h2 {
        font-size: 25px;
    }
    #verify {
        margin-top: -220px;
    }
    .d-flex {
        display: block;
        text-align: center;
    }
    .mint-content>.d-flex {
        display: flex;
    }
    #verify .btn.gradient-btn.verify-social.twitter{
        margin-bottom: 0px;
        margin-right: 10px;
    }
    #verify h1 {
        font-size: 35px;
    } 
    #verify, #verify input {
        font-size: 20px !important;
        letter-spacing: 2px !important;
    }
    #verify h3 {
        font-size: 23px !important;
        letter-spacing: 5px;
    }
    #verify .verify-input {
        display: block;
    }
    #verify .verify-input input {
        width: 96%;
        background-color: #e5e3e3;
        margin-top: 10px;
        border-radius: 5px;
        color: #000;
        font-family: 'BebasNeue' !important;
    }
    #verify input {
        width: 96%;
        background-color: #e5e3e3;
        margin-top: 10px;
        border-radius: 5px;
        color: #000;
        font-family: 'BebasNeue' !important;
    }
    .step-item:not(:first-child) {
        margin-top: 15%;
    }

    .step-loading {
        margin: auto;
    }
    #verify .gradient-btn {
        margin-top: 5%;
    }
    .step-item .text-right {
        text-align: center;
    }
}
@media (max-width: 350px) {
    #verify .step-content .gradient-btn {
        font-size: 12px;
    }
}