.policy-block {
    padding-top: 250px;
    font-size: 15px;
    line-height: 1.4;
    padding-bottom: 15%;
}
.policy-block p {
    font-size: 15px;
    line-height: 1.3;
    opacity: 1;
}
.policy-block ul.number {
    list-style: auto;
}
.policy-block a {
    color: #fff;
}
.credit-btn {
    font-family: "BebasNeue" !important;
}