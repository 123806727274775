.mb-2 {
  margin-bottom: 2rem;
}
.auction {
  background-color: #000;
}
.section-des {
  font-size: 18px;
  line-height: 1.8;
  margin: 3% 0 5% 0;
}
.auction .roadmap-body {
  overflow: hidden;
}
.auction .intro {
  background-image: url(../../assets/images/auction/auction-bg.png) !important;
  padding-bottom: 15%;
}
.auction .intro .main-title {
  font-size: 90px;
  line-height: 1.2;
  margin-top: 40px;
  letter-spacing: 2px;
  text-shadow: 7px -4px 0px #056bff, 10px 5px 0px #f70000 !important;
}
.auction .header-bottom {
  background-image: url(../../assets/images/reflection/reflection_hearder_bg2.png) !important;
  margin-top: -20%;
  padding-top: 15%;
  padding-bottom: 4%;
  position: relative;
  background-size: cover;
}
.auction .section-body {
  padding-top: 2%;
}
.auction .left-body {
  margin-top: 5%;
  position: relative;
  z-index: 1;
}
.auction .left-body h2 {
  letter-spacing: 12px;
}
.auction p {
  font-size: 21px;
}
.auction .intro .mt-5 {
  margin-top: 10% !important;
}
.auction .left-body p {
  line-height: 1.5;
  max-width: 530px;
  letter-spacing: 1px;
}
.auction .card {
  background: #000;
  text-align: left;
  border: 2px solid #cc9035;
  border-radius: 5px;
}
.auction .card-content {
  padding: 50px 50px 70px;
  position: relative;
}
.auction h5 {
  font-family: "Headline";
  background: linear-gradient(
    90deg,
    #9c5205 0%,
    #c17717 25%,
    #e8c15b 50%,
    #c17717 75%,
    #9c5205 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  display: inline-block;
  margin: 0;
}
.auction .card-content h4 {
  font-family: "Poppins SemiBold";
  line-height: 1.3;
  font-size: 20px;
  margin: 20px 0;
}
.auction .card-content p {
  font-size: 14px;
}
.auction .card-content .number {
  position: absolute;
  border-radius: 50%;
  padding: 5px 13px;
  right: 75px;
  top: 85px;
  background: linear-gradient(
    130deg,
    #0e60f1 0%,
    #5344a9 40%,
    #8f2a6a 60%,
    #c9122e 100%
  );
}
.auction .header-bottom h1 {
  text-shadow: none !important;
}
.auction-main {
  background: url(../../assets/images/reflection/explain_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  padding-bottom: 37%;
}
.auction-main .section-body {
  padding-top: 0;
}
.auction .filter-options {
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
}
.auction .filter-options button {
  background: transparent;
  font-size: 16px;
  font-family: "Poppins Bold" !important;
  border: none;
  transition: 0.5s;
}
/* .auction .filter-options button:hover {
    color: #000;
    padding: 15px 50px;
    background: linear-gradient(90deg, #984f05, #d49a3f 100%);
    border: 1px solid #ffff6a;
    border-radius: 10px; 
} */
.auction .filter-options .active {
  color: #000;
  padding: 15px 50px;
  background: linear-gradient(90deg, #984f05, #d49a3f 100%);
  border: 1px solid #ffff6a;
  border-radius: 10px;
}
/* .auction-items {
  margin-top: 70px;
} */
.auction-description {
  margin-top: 10px;
}
.auction-description h3 {
  font-family: 'OswaldStencil Bold';
  font-size: 33px;
  background: linear-gradient(
    90deg,
    #9c5205 0%,
    #c17717 25%,
    #e8c15b 50%,
    #c17717 75%,
    #9c5205 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  margin: 0;
}
.auction-description p {
  font-size: 17px;
}
.auction .header-content div {
  padding-right: 0px;
  padding-left: 0px;
}
.auction img.header-bounty-img {
  margin-left: -10%;
}
.auction .intro .btn {
  padding: 10px 5px 10px 25px;
}
.auction .intro .btn img {
  margin-left: 15px;
}
.auction .container-fluid {
  padding: 0 5% 5% 5%;
  margin-top: -3%;
}
.auction .explain-card-list {
  background-image: url(../../assets/images/reflection/explain_bg2.png);
  background-size: contain;
  margin-top: -32%;
  z-index: 999;
  position: relative;
  padding-bottom: 15%;
  min-height: 102vw;
}

.auction .explain2 {
  background-image: url("../../assets/images/reflection/explain_bg2.png");
  padding-top: 5%;
  margin-top: -5%;
}
.auction-steps {
  display: flex;
  align-items: center;
}

.auction-steps-image {
  flex: 6;
}
.auction-steps-title {
  width: 100%;
  text-align: left;
  flex: 4;
}

.auction-steps-title h1 {
  text-shadow: 4px 4px 0px #056bff, -4px -4px 0px #f70000 !important;
  max-width: 500px;
  margin: auto;
}

.slick-slide {
  height: auto;
}
.auction .roadmap-body .roadmap-item:nth-child(1) .roadmap-item-title img {
  margin-right: -40%;
}
.auction .roadmap-body {
  margin-top: 0;
}
.auction .roadmap-body .roadmap-item .roadmap-item-no {
  border: none;
  padding: 0;
  width: 20px;
  min-width: 20px;
  height: 20px;
}
.auction .roadmap-body .roadmap-item:nth-child(1) .roadmap-item-no {
  top: 25%;
}
.auction .roadmap-body .roadmap-item:nth-child(2) .roadmap-item-no {
  top: 12%;
}
.auction .roadmap-body .roadmap-item:nth-child(3) .roadmap-item-no {
  top: 30%;
}
.auction .roadmap-body .roadmap-item .roadmap-item-no span {
  background: #cf9033;
  position: relative;
}
.auction .roadmap-body .roadmap-item .roadmap-item-no span:after {
  position: absolute;
  content: "";
  width: 29px;
  border-top: 2px dashed #8c8ca1;
  top: 50%;
  left: 130%;
}
.auction .roadmap-body .roadmap-item:nth-child(2n) .roadmap-item-no span:after {
  right: 130%;
  left: initial;
}
.auction .roadmap-body .roadmap-item.right .roadmap-item-title {
  text-align: center;
}
.step1-element {
  position: absolute;
  right: -120px;
  max-width: 350px;
}
.step2-element {
  position: absolute;
  left: -120px;
  max-width: 350px;
}
.auction .roadmap-body .roadmap-item .roadmap-item-content {
  text-align: left;
  padding-left: 60px;
}
.roadmap-header {
  font-family: "Poppins Bold" !important;
  font-family: "Poppins Bold" !important;
}
.auction .roadmap-body .roadmap-item .roadmap-item-content h3 {
  font-family: "Poppins Bold" !important;
  font-family: "Poppins Bold" !important;
  margin: 7px 0;
}
.auction .roadmap-body .roadmap-item .roadmap-item-content p {
  font-family: "Poppins Medium";
  line-height: 1.5;
  font-size: 18px;
}
.auction .roadmap-body .roadmap-item:nth-child(2n) .roadmap-item-content {
  padding-right: 60px;
  padding-left: 0;
  text-align: right;
}
.auction .roadmap-body .roadmap-item:nth-child(3) .roadmap-item-content {
  margin-top: -125px;
}
.auction .roadmap-body .roadmap-item:nth-child(2) .roadmap-item-title img {
  margin-left: -35%;
  max-width: 180%;
}
.auction .roadmap-body .roadmap-item:nth-child(3) .roadmap-item-title img {
  margin-left: -30%;
  max-width: 120%;
}
.auction .roadmap-body .roadmap-item:nth-child(2) {
  padding: 0;
}
.step-btn {
  font-family: "Poppins SemiBold" !important;
  background: transparent;
  border: 1px solid #bb9d51;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 18px;
  transition: 0.8s;
}
.step-btn:hover {
  background: #bb9d51;
}
.explain-footer {
  margin-top: -17%;
  z-index: 1;
  position: relative;
}
.explain-footer img {
  width: 100%;
}
.auction .roadmap-body::before {
  top: 25%;
  height: 55%;
  border: 2px dashed #8c8ca1;
}
.up-arrow {
  top: 22%;
  position: absolute;
  left: 51.2%;
}
.down-arrow {
  position: absolute;
  left: 51.2%;
  bottom: 13.2%;
}
@media (min-width: 1850px) {
  .auction .container-fluid {
    max-width: 1850px;
  }
}
@media (max-width: 1550px) {
  .auction .intro {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media (max-width: 1440px) {
  .auction .container-fluid {
    padding: 5%;
  }
  .auction .intro .main-title {
    font-size: 80px !important;
    line-height: 1;
  }
  .auction .explain-card-list {
    background-size: contain !important;
  }
}
@media (max-width: 1270px) {
  #auction-focus {
    margin-bottom: 15%;
  }
}
@media (max-width: 1200px) {
  .auction .section-body {
    padding-top: 7%;
  }
  .auction .card-content .number {
    right: 50px;
  }
  .auction .card-content p br {
    display: none;
  }
  .auction .roadmap-body .roadmap-item .roadmap-item-content {
    padding-left: 70px;
  }
  .auction .roadmap-body .roadmap-item:nth-child(2n) .roadmap-item-content {
    padding-right: 70px;
  }
  .up-arrow,
  .down-arrow {
    left: 51.4%;
  }
}
@media (max-width: 1120px) {
  .auction .intro .main-title {
    font-size: 65px !important;
  }
  .auction .left-body p {
    font-size: 18px;
  }
  .auction img.header-bounty-img {
    margin-left: 0%;
  }
  .auction-steps-title h1 {
    font-size: 112px;
  }
}

@media (max-width: 1130px) {
  .auction .navbar-right li {
    display: inline-block !important;
  }
  .auction .container-fluid {
    overflow: hidden !important;
  }
}
@media (max-width: 991px) {
  .auction .section-body {
    padding-top: 80px;
  }
  .auction .header-bottom {
    margin-top: -25%;
  }
  .auction .left-body {
    text-align: center;
    padding-bottom: 50px !important;
  }
  .auction .left-body p {
    max-width: 100%;
  }
  .auction .intro .main-title {
    font-size: 80px !important;
  }
  .auction .right-body {
    padding: 0 100px;
  }
  .card img {
    width: 100%;
  }
  .auction-steps-title h1 {
    font-size: 90px;
  }
  .auction .roadmap-body .roadmap-item:nth-child(1) .roadmap-item-title img {
    margin-right: 0;
  }
  .step1-element {
    right: -100px;
  }
  .step2-element {
    left: -100px;
  }
  .auction .roadmap-body::before {
    top: 30%;
  }
  .up-arrow,
  .down-arrow {
    left: 51.9%;
  }
  .down-arrow {
    bottom: 8%;
  }
  .up-arrow {
    top: 26.3%;
  }
  .auction .roadmap-body .roadmap-item:nth-child(3) .roadmap-item-no {
    top: 15%;
  }
  .auction-main {
    background-size: contain !important;
  }
}

@media (max-width: 767px) {
  #header.auction h1 {
    margin-top: 0%;
  }
  .auction .intro h1 {
    font-size: 40px;
  }
  .auction .intro .row.d-flex.align-items-center.justify-center {
    display: flex !important;
    padding-right: 15px !important;
  }
  h3 {
    font-size: 22px !important;
  }
  .auction p {
    font-size: 18px;
  }
  .row.d-flex {
    display: block;
  }
  .auction .intro {
    padding-top: 70px;
  }
  .auction .navbar-header {
    display: inline-block !important;
  }
  .auction #navbarCollapse {
    float: right !important;
  }
  .auction #navbarCollpase .nav {
    display: flex;
  }
  .auction .navbar-collapse {
    background-color: transparent !important;
    box-shadow: inset 0 0px 0 rgb(255 255 255 / 10%);
  }
  body {
    overflow-x: hidden;
  }
  .explain-card-list {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  .auction .section-body {
    padding-top: 110px;
  }
  .auction-steps {
    flex-direction: column;
  }
  .auction-steps-title {
    text-align: center;
  }
  .auction .roadmap-body .roadmap-item {
    flex-direction: column;
  }
  .auction .roadmap-body .roadmap-item.right .roadmap-item-title,
  .roadmap-body .roadmap-item.left .roadmap-item-content {
    margin-right: 0;
  }
  .auction .roadmap-body .roadmap-item .roadmap-item-no {
    display: none;
  }
  .auction .roadmap-body::before {
    display: none;
  }
  .up-arrow,
  .down-arrow {
    display: none;
  }
  .auction .roadmap-body .roadmap-item .roadmap-item-content {
    padding-left: 0;
  }
  .auction .roadmap-body .roadmap-item:nth-child(2n) .roadmap-item-content {
    padding-right: 0;
    text-align: left;
  }
  .auction .roadmap-body .roadmap-item .roadmap-item-title,
  .auction .roadmap-body .roadmap-item .roadmap-item-content {
    width: 100%;
  }
  .auction .roadmap-body .roadmap-item:nth-child(3) .roadmap-item-content {
    margin-top: 0;
  }
  .auction .roadmap-body .roadmap-item:nth-child(3) .roadmap-item-title img {
    max-width: 100%;
  }
  .explain-footer {
    margin-top: -8%;
  }
}
@media (max-width: 650px) {
  .auction .main-title {
    font-size: 70px !important;
  }
  .auction .right-body {
    padding: 0 40px;
  }
}
@media (max-width: 580px) {
  .auction .breackdown-block p {
    font-size: 22px;
    line-height: 1.2;
  }
  .auction .intro .main-title {
    font-size: 60px !important;
  }
  .auction .left-body h2 {
    letter-spacing: 10px;
  }
}
@media (max-width: 550px) {
  .auction .filter-options {
    flex-direction: column;
  }
}
@media (max-width: 500px) {
  .auction .left-body h2 {
    letter-spacing: 5px;
  }
  .auction .intro .main-title {
    font-size: 50px !important;
  }
  .auction .left-body p {
    font-size: 16px;
  }
}
@media (max-width: 450px) {
  .auction .section-title {
    padding: 0 1%;
  }
  .auction .breackdown-block p {
    font-size: 20px;
    line-height: 1.2;
  }
  .explain-card-list {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
  .auction .section-body {
    padding-top: 140px;
  }
  .auction .card-content {
    padding: 50px 20px 70px;
  }
  .auction .card-content .number {
    right: 40px;
    top: 60px;
  }
}
@media (max-width: 400px) {
  .auction p {
    font-size: 16px;
  }
  .auction .section-body {
    padding-top: 90px;
  }
  .auction .intro .main-title {
    font-size: 40px !important;
  }
  .auction .card-content .number {
    right: 25px;
    top: 15px;
  }
  .auction .card-content h4 {
    margin: 5px 0;
  }
  .auction .card-content h4 br {
    display: none;
  }
}
@media (max-width: 375px) {
  .auction .breackdown-block .section-title {
    font-size: 40px;
  }
}
