.reflections {
    background-color: #000;
}
.section-des {
    font-size: 18px;
    line-height: 1.8;
    margin: 3% 0 5% 0;
}
.reflections .intro {
    background-image: url(../../assets/images/reflection/reflection_hearder_bg.png) !important;
    padding-bottom: 5%;
}
.reflections .header-bottom {
    background-image: url(../../assets/images/reflection/reflection_hearder_bg2.png) !important;
    margin-top: -17%;
    padding-top: 20%;
    position: relative;
}
.reflections .header-hunter {
    transform: scale(1.1);
    z-index: 0;
    position: relative;
}
.reflections .section-body {
    padding-top: 2%;
}
.reflections .left-body {
    margin-top: 3%;
    position: relative;
    z-index: 1;
}
.reflections p {
    font-size: 20px;
}
.reflections .intro .mt-5 {
    margin-top: 10% !important;
}
.reflections .intro h4 {
    font-family: "stimul";
    color: #3df6f6;
    font-size: 19px;
}
.reflections .intro p {
    font-family: "Bicyclette";
    line-height: 1.25;
}
.reflections .reflection-content{
    max-width: 1350px;
    margin: auto;
}
.reflections .header-content div{
    padding-right: 0px;
    padding-left: 0px;
}
.reflections img.header-bounty-img {
    margin-left: -10%;
}
.reflections .intro .btn {
    padding: 10px 5px 10px 25px;
}
.reflections .intro .btn img {
    margin-left: 15px;
}
.reflections .focus-bg1 {
    padding-top: 20%;
    margin-top: -15%;
    background-image: url('../../assets/images/reflection/explain_bg.png');
    min-height: 115vw;
}
.reflections .card-description {
    max-width: 315px;
    margin-left: auto;
    margin-right: auto;
}
.reflections .focus-card .card-description.first {
    background-image: url('../../assets/images/reflection/explain-card1.png');
    min-height: 435px;
}
.reflections .focus-card .card-description.second {
    background-image: url('../../assets/images/reflection/explain-card2.png');
    min-height: 610px;
}
.reflections .focus-card .card-description.third {
    background-image: url('../../assets/images/reflection/explain-card3.png');
    min-height: 550px;
}
.reflections .focus-card .card-description.fourth {
    background-image: url('../../assets/images/reflection/explain-card4.png');
    min-height: 420px;
}
.reflections .focus-card .card-description {
    display: block;
    padding: 55% 10% 10% 10%;
}
.reflections .card-img {
    height: 155px;
}
.reflections .focus-card .card-description p{
    font-size: 14px;
}
.reflections .focus-card .card-description h3{
    font-size: 26px;
}
.reflections .container-fluid {
    padding: 0 5% 5% 5%;
    margin-top: -3%;
}
.reflections .explain-card-list {
    background-image: url(../../assets/images/reflection/explain_bg2.png);
    margin-top: -10%;
    padding-top: 20%;
    padding-bottom: 20%;
    min-height: 102vw;
}
.reflections .explain-card-list .card-list-body .explain-card:nth-child(odd) {
    border: 2px solid #f70817;
}
.reflections .explain-card-list .card-list-body .explain-card:nth-child(odd) h3 {
    color: #f70817;
}
.reflections .explain-card-list .card-list-body .explain-card:nth-child(even) {
    border: 2px solid #0873e7;
}
.reflections .explain-card-list .card-list-body .explain-card:nth-child(even) h3 {
    color: #0873e7;
}
.reflections .explain-card {
    margin-bottom: 15px;
    background-color: rgb(255, 255, 255, 0.03) !important;
    padding: 10px 5% !important;
    min-height: 90px !important;
    position: relative;
}
.reflections .explain-card h3 {
    font-family: 'BebasNeue';
    font-size: 30px;
}
.reflections .explain-card p{
    font-weight: bold;
    color: #fff;
    font-size: 23px;
}

.reflections .explain2 {
    background-image: url('../../assets/images/reflection/explain_bg2.png');
    padding-top: 5%;
    margin-top: -5%;
}
.reflections .card-content {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    padding: 7% 12%;
}
.reflections .card-content h4 {
    font-size: 26px;
    color: rgb(162, 243, 242);
    font-weight: bold;
    margin-top: 10%;
}
.reflections .card-content p {
    font-size: 20px;
    line-height: 1.5;
}
.reflections .explain-card-list {

}
.reflections .number-block {
    background-image: url(../../assets/images/reflection/number-bg.png);
    padding-top: 5%;
}
.reflections .number-block .btn {
    font-family: 'Poppins' !important;
    font-size: 20px;
    padding: 15px 30px;
}
.reflections .number-block .slider-content {
    padding: 0;
    padding-top: 1%;
    width: 100%;
    /* position: relative; */
    align-items: center;
    margin: 0;
  }
  
  .reflections .number-block .slick-list {
    overflow: visible;
  }
  
  .reflections .number-block .slick-slider {
    width: 100%;
    padding: 0 3%;
  }
  
  .slick-slide {
    height: auto;
  }
  
  .reflections .number-block .slick-track {
    display: flex;
    padding: 60px 0;
    /* max-width:100%; */
  }
  
  .reflections .number-block .slider-item {
    position: relative;
    padding: 0 10px;
    overflow: visible;
  }
  
  .reflections .number-block .slider-item-bg {
    width: 100%;
  }
  
  .reflections .number-block .slider-item-img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    max-width: none;
    z-index: 1;
  }
  
  .reflections .number-block .slider-item.item-1 .slider-item-img,
  .reflections .number-block .slider-item.item-5 .slider-item-img {
    width: 90% !important;
    top: -73px !important;
    left: 44%;
  }
  
  .reflections .number-block .slider-item.item-2 .slider-item-img,
  .reflections .number-block .slider-item.item-6 .slider-item-img {
    width: 102% !important;
    top: -73px;
    left: 39%;
  }
  
  .reflections .number-block .slider-item.item-3 .slider-item-img,
  .reflections .number-block .slider-item.item-7 .slider-item-img {
    width: 126% !important;
    left: 68% !important;
    top: -16% !important;
  }
  
  .reflections .number-block .slider-item.item-4 .slider-item-img,
  .reflections .number-block .slider-item.item-8 .slider-item-img {
    width: 93% !important;
    left: 52% !important;
    top: -14% !important;
  }
  
  .reflections .number-block .slider-item.item-1,
  .reflections .number-block .item-3,
  .reflections .number-block .item-5,
  .reflections .number-block .item-7 {
    margin-top: 22%;
  }

.reflections .breackdown-block {
    background-image: url(../../assets/images/reflection/breackdown-bg.png);
    padding-top: 5%;
    padding-bottom: 15%;
}
.reflections .breackdown-block p {
    font-size: 25px;
}
.eapps-countdown-timer-has-background {
    padding: 0px !important;
}
#eapps-countdown-timer-1 .eapps-countdown-timer-header-title, #eapps-countdown-timer-1 .eapps-countdown-timer-bar-close {
    color: #fff !important;
}
#eapps-countdown-timer-1.eapps-countdown-timer, #eapps-countdown-timer-1 .eapps-countdown-timer-inner {
    color: rgb(255, 27, 27);
    max-width: 90vw;
    margin: 0 auto;
}
.reflections .explain3 {
    /* background-image: url('../../assets/images/reflection/whitepaper_bg.png'); */
    min-height: 76vw;
    padding-top: 10%;
    margin-top: -5%;
}
.reflections .whitepaper {
    padding-top: 20px;
}
.reflections .whitepaper .eape-grid-component {
    display: flex;
    justify-content: center;
    column-gap: 10%;
    width: 100%;
}
.reflections .whitepaper .eape-grid-item {
    width: auto !important;
}
.reflections .whitepaper .eape-grid-item img {
    max-width: 25vw;
}
.whitepaper .eape-grid-component.jsx-3385750633 { 
    justify-content: center;
}
.whitepaper .eape-item-iconContainer {
    -webkit-align-self: center;
    align-self: center;
}
.whitepaper .eape-item-name.jsx-4113780251 {
    font-size: 18px !important;
    color: #fff !important;
}
.whitepaper .eape-icon-image.jsx-576420901 {
    max-width: 200px;
    max-height: none !important;
}
.whitepaper .eape-item-blockLink {
    height: auto !important;
}
.whitepaper .eape-item-link.jsx-4113780251 {
    font-size: 17px !important;
}
.reflections .whitepaper .eape-item-wrapper {
    margin-top: 10%;
}
.reflections .graphic {
    padding: 1% 0 4% 0;
}
.reflections .graphic .box {
    background-color: #171717;
    border: 1px solid #ff2837;
    width: max-content;
    margin: 15px auto;
    padding: 5px 15px;
    border-radius: 15px;
    position: relative;
    font-family: "stimul";
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.reflections .graphic .graphic-top {
    position: relative;
    padding-bottom: 15px;
}
.reflections .graphic .graphic-top::before {
    content: "";
    height: 99%;
    left: 50%;
    width: 3px;
    position: absolute;
    background-color: #ff2837;
}
.reflections .graphic .graphic-top::after {
    content: "";
    left: 50%;
    bottom: 5px;
    position: absolute;
    border-style: solid;
    border-color: #ff2837 #ff2837 transparent transparent;
    border-width: 9px;
    transform: scale(1.5);
    transform: scale(0.6, 1.1) rotate(135deg) translate(54%, 46%);
}
.reflections .graphic .graphic-bottom {
    display: flex;
    column-gap: 3%;
    position: relative;
    padding: 25px 0;
}
.reflections .graphic .graphic-bottom .box{
    padding: 0px;
}
.reflections .graphic .graphic-bottom .box div{
    padding: 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;
}
.reflections .graphic .graphic-bottom .box::before {
    content: "";
    width: 3px;
    height: 35px;
    top: -40px;
    left: 50%;
    background-color: #ff2837;
    position: absolute;
}
.reflections .graphic .graphic-bottom .box::after {
    content: "";
    left: 50%;
    top: -25px;
    position: absolute;
    border-style: solid;
    border-color: #ff2837 #ff2837 transparent transparent;
    border-width: 9px;
    transform: scale(1.5);
    transform: scale(0.6, 1.1) rotate(135deg) translate(54%, 46%);
}

.reflections .graphic .graphic-bottom .box:first-child div:before,  .reflections .graphic .graphic-bottom .box:last-child div:before{
    content: "";
    width: 3px;
    height: 47px;
    bottom: -48px;
    left: 50%;
    background-color: #ff2837;
    position: absolute;
}

.reflections .graphic .graphic-bottom .box:first-child div::after {
    content: "";
    width: 75%;
    height: 3px;
    left: 50%;
    background-color: #ff2837;
    top: -40px;
    position: absolute;
}
.reflections .graphic .graphic-bottom .box:nth-child(2) div::after, .reflections .graphic .graphic-bottom .box:nth-child(3) div::after {
    content: "";
    width: 120%;
    height: 3px;
    left: -5%;
    background-color: #ff2837;
    top: -40px;
    position: absolute;
}
.reflections .graphic .graphic-bottom .box:nth-child(4) div::after {
    content: "";
    width: 60%;
    height: 3px;
    right: 50%;
    background-color: #ff2837;
    top: -40px;
    position: absolute;
}
.reflections .graphic .graphic-bottom .box span {
    display: block;
    width: 100%;
    height: 3px;
    background-color: #ff2837;
    position: absolute;
    bottom: -50px;
    left: 50%;
}
.reflections .graphic .graphic-bottom .box:last-child span {
    left: -49%;
}
.reflections .graphic .box.last {
    margin-top: -14px;
    font-size: 17px;
    background-image: linear-gradient(
        -180deg
        , #fe7c06, #f00760);
    border: 0px;
}
.reflections .graphic .box.last::before {
    content: "";
    width: 25%;
    height: 3px;
    left: -26%;
    background-color: #ff2837;
    top: 49%;
    position: absolute;
}
.reflections .graphic .box.last::after {
    content: "";
    width: 25%;
    height: 3px;
    right: -26%;
    background-color: #ff2837;
    top: 49%;
    position: absolute;
}
.reflections .graphic .box.last div::before {
    content: "";
    left: -22px;
    top: 30%;
    position: absolute;
    border-style: solid;
    border-color: #ff2837 #ff2837 transparent transparent;
    border-width: 9px;
    transform: scale(1, 0.6) rotate(44deg);
}
.reflections .graphic .box.last div::after {
    content: "";
    right: -22px;
    top: 30%;
    position: absolute;
    border-style: solid;
    border-color: #ff2837 #ff2837 transparent transparent;
    border-width: 9px;
    transform: scale(1, 0.6) rotate(225deg);
}

.reflections .graphic .box.strong-border {
    border-width: 4px;
}
.reflections .graphic .box.circle {
    border-radius: 50%;
    padding: 1% 2.5%;
}
.reflections .graphic div img {
    width: 60px;
}
.reflections .footer {
    /* background-image: url(../../assets/images/reflection/footer_bg.png); */
    background-position: bottom;
    margin-top: 0%;
    padding-bottom: 32%;
    min-height: 95vh;
    position: relative;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;

}
.reflections .footer-content {
    position: absolute;
}
.reflections .footer .reflection-content {
    margin-top: 2%;
}
.footer p {
    font-size: 14px;
    line-height: 1.25;
}
.disclaimer {
    padding-top: 2%;
    padding-bottom: 10%;
}
.disclaimer p{
    font-size: 15px !important;
    line-height: 1.2;
}
.whitepaper {
    padding-bottom: 3%;
}
.reflections .whitepaper-link {
    max-width: 400px;
    display: block;
    margin: auto;
    margin-top: 3%;
    transition: all 0.5s ease-in-out;
}
.reflections .whitepaper-link:hover {
    transform: scale(1.05);
}
@media (min-width: 1850px) {
    .reflections .container-fluid {
        max-width: 1850px;
    }
}
@media (max-width: 1550px) {
    .reflections .header-hunter {
        transform: scale(1);
    }
    .reflections .intro {
        padding-left: 5%;
        padding-right: 5%;
    }
}
@media (max-width: 1440px) {
    .reflections .reflection-content {
        max-width: 1200px;
    }
    .reflections .container-fluid {
        padding: 5%;
    }
    
}
@media (max-width: 1300px) {
    .reflections .reflection-content {
        max-width: 95%;
    }
}
@media (max-width: 1120px) {
    .reflection-content .row {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
    .reflections img.header-bounty-img {
        margin-left: 0%;
    }
}

@media (max-width: 1130px) {
    .reflections .navbar-right li {
        display: inline-block !important;
    }
    .reflections .focus-card .card-description {
        display: block;
    }
    .reflections .focus-card .card-description p {
        line-height: 1.3;
    }
    .reflections .container-fluid {
        overflow: hidden !important;
    }
    .reflection-content {
        overflow: hidden !important;
    }
    .reflections .card-content p {
        font-size: 18px;
        line-height: 1.3;
    }
}
@media (max-width: 1000px) {
    .reflections .footer {
        margin-top: 0%;
    }
}
@media (max-width: 991px) {
    .reflections .section-body {
        padding-top: 160px;
    }
    .reflections .header-bottom {
        margin-top: -30%;
    }
}

@media (max-width: 860px) {
    .reflections .graphic .graphic-bottom .box:last-child span {
        left: -48%;
    }
    .reflections .graphic .box.last div::after {
        right: -20px;
    }
    .reflections .graphic .box.last div::before {
        left: -20px;
    }
    .reflections .graphic .box.last::before, .reflections .graphic .box.last::after {
        height: 0px;
    } 
}
@media (max-width: 767px) {
    #header.reflections h1 {
        margin-top: 0%;
    }
    .reflections .intro h1 {
        font-size: 40px;
    }
    .reflections .intro .row.d-flex.align-items-center.justify-center {
        display: flex !important;
        padding-right: 15px !important;
    }
    .reflections .reflection-content {
        max-width: 90%;
    }
    h3 {
        font-size: 22px !important;
    }
    .reflections p {
        font-size: 18px;
    }
    .row.d-flex {
        display: block;
    }
    .reflections .footer {
        margin-top: 0%;
        padding-bottom: 35%;
    }
    .reflections .intro {
        padding-top: 70px;
    }
    .reflections .navbar-header {
        display: inline-block !important;
    }
    .reflections #navbarCollapse {
        float: right !important;
    }
    .reflections #navbarCollpase .nav {
        display: flex;
    }
    .eapps-countdown-timer-header-title-text {
        font-size: 30px !important;
    }
    .reflections .navbar-collapse {
        background-color: transparent !important;
        box-shadow: inset 0 0px 0 rgb(255 255 255 / 10%);
    }
    body {
        overflow-x: hidden;
    }
    .reflections .focus-card .card-description p {
        font-size: 15px;
    }
    .explain-card-list {
        padding-left: 10% !important; 
        padding-right: 10% !important; 
    }
    
    .reflections .whitepaper .eape-grid-item img {
        max-width: 40vw;
    }
    .reflections .focus-card .card-description {
        min-height: auto;
    }
}
@media (max-width: 650px) {
    .reflections .graphic .box {
        font-size: 12px;
    } 
}
@media (max-width: 580px) {  
    .reflections .graphic .box.last div::after, .reflections .graphic .box.last div::before {
        border-width: 0px;
    }
    .reflections .graphic .graphic-bottom {
        column-gap: 1%;
    }
    .reflections .graphic .box.circle {
        padding: 1% 4.5%;
    }
    .reflections .breackdown-block p {
        font-size: 22px;
        line-height: 1.2;
    }
}
@media (max-width: 550px) {
    .reflections .card-content p {
        font-size: 18px;
        line-height: 1.5;
    }
    .reflections .card-content h4 {
        font-size: 20px;
    }
    .reflections .footer {
        padding-bottom: 40%;
    }
    .reflections .graphic .graphic-bottom .box div {
        padding: 5px 5px;
    }
    .reflections .number-block .btn {
        font-family: 'Poppins' !important;
        font-size: 18px !important;
        letter-spacing: 0px !important;
        padding: 10px 15px;
    }
}
@media (max-width: 500px) {
    .reflections .card-content p {
        font-size: 17px;
        line-height: 1.2;
    }
    .footer-content {
        bottom: 0%;
    }
}
@media (max-width: 450px) {
    .reflections .section-title {
        padding: 0 1%;
    }
    .reflections .focus-bg1 .section-title {
        font-size: 30px;
    }
    .reflections .explain-card p {
        font-size: 16px;
    }
    .reflections .card-content img {
        max-width: 10vw;
        max-height: 10vw;
    }
    .reflections .card-content h4 {
        font-size: 18px;
    }
    .reflections .footer {
        padding-bottom: 50%;
    }
    .footer p {
        font-size: 14px;
        line-height: 1.25;
    }
    .reflections .graphic .box {
        font-size: 11px;
    }
    .reflections .graphic .box.last {
        font-size: 15px;
    }
    .reflections .reflection-content.graphic {
        max-width: 99%;
    }
    .reflections .breackdown-block p {
        font-size: 20px;
        line-height: 1.2;
    }
    .explain-card-list {
        padding-left: 0% !important;
        padding-right: 0% !important;
    }
    .reflections .section-body {
        padding-top: 140px;
    }
}
@media (max-width: 400px) {
    .reflections .card-content h4 {
        font-size: 16px;
    }
    .reflections .card-content p {
        font-size: 15px;
    }
    .reflections .card-content {
        padding: 7% 5%;
    }
    .reflections p {
        font-size: 16px;
    }
    .reflections .explain-card p {
        font-size: 14px;
    }
}
@media (max-width: 375px) {
    .reflections .graphic .box {
        font-size: 10px;
    }
    .reflections .graphic .box.last {
        font-size: 13px;
    }
    .reflections .breackdown-block .section-title {
        font-size: 40px;
    }
}
@media (max-width: 340px) {
    .reflections .graphic .box {
        font-size: 9px;
        letter-spacing: -0.5;
    }
}

@media (max-width: 300px) {
    .reflections .card-content p {
        font-size: 13px;
    }
}
@media (max-width: 290px) { 
    .reflections #navbarCollapse {
        display: none;
    }
}