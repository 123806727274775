#header.vault .intro {
    min-height: 112vh;
    background-image: url(../../assets/images/vault/header-bg.jpg);
}
.vault .header-content {
    padding: 5% 2% 0 2%;
}
.vault .header-content h3 {
    line-height: 1.5;
    max-width: 850px;
    margin: auto;
}
#vault {
    margin-top: -16%;
    position: relative;
}
#vault .purpose-body1 {
    margin-top: 0%;
    padding-top: 20%;
    background-image: url(../../assets/images/vault/vault-bg.png);
    background-size: 100% !important;
    min-height: 100vw;
}
#vault .lions-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
#vault .dashboard {
    margin-bottom: 10%;
}
#vault .dashboard-menu .btn{
    padding: 10px 30px;
    margin: 5px 10px;
    box-shadow: none;
}
#vault .dashboard-submenu .btn{
    padding: 5px 20px;
    margin: 2px 5px;
    box-shadow: none;
}
#vault .react-tabs__tab--selected {
    background: #0467fc;
    border-color: #0467fc !important;
    color: #fff;
    border-radius: 0;
}
#vault .dashboard-content {
    border: 5px solid #94540c7e;
    padding: 5%;
    margin-top: 5%;
    text-align: left;
}
#vault .dashboard-content p {
    word-break: break-all;
}
.vault-footer {
    margin-top: -5% !important;
}
.vote-area div, .vote-area label, .vote-area span, .vote-area select {
    color: #fff !important;
}
.vote-area .TextControlBase__TextControlBasePlaceholder-qtroj4-8 {
    opacity: 0.45;
}