.auction-sub .intro {
    background-image: url(../../assets/images/auction/auction-sub-bg.png) !important;
    padding-bottom: 15%;
}
.auction-sub .intro .title {
    margin-top: 0px !important;
    line-height: 0.6 !important;
}
.auction-sub .btn.disable {
    opacity: 0.5;
}

.auction-modal .select-item {
    position: relative;
}
.auction-modal .select-item div {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 25px;
    cursor: pointer;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
}
.auction-modal .select-item:hover div i {
    transition: all 0.5s ease-in-out;
}
.auction-modal .select-item:hover div {
    background-color: rgba(0, 0, 0, 0.493);
    visibility: visible;
}
.auction-modal .select-item:hover div i {
    transform: scale(1.3);
}