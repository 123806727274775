.crossmint-button {
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, #b66c13, #e8c15b 25%, #b16402 50%, #e8c15b 75%, #b66c13) !important;
    margin: auto;
    margin-bottom: 10px;
    font-size: 25px;
    font-weight: 100 !important;
    background-size: 200% 100% !important;
    transition: all 0.4s ease-in-out !important;
}
.crossmint-button:hover {
    opacity: 1 !important;
    background-position: 100% 0;
}
.creditcard-img {
    margin: 0 5px 5px 5px;
    border-radius: 5px;
}