.loading {
    margin: auto;
    display: inline-block;
}

.section-des {
    font-size: 18px;
    line-height: 1.8;
    margin: 3% 0 5% 0;
}

.mint .intro {
    background-image: url(../../assets/images/mint/mint-bg.png) !important;
    min-height: 55vw;
    padding-top: 250px;
    padding-bottom: 5%;
    background-size: cover;
    background-repeat: no-repeat;
}
.mint p {
    font-size: 24px;
}

.mint .intro .mt-5 {
    margin-top: 10% !important;
}

.mint .intro h4 {
    color: #3df6f6;
    font-size: 19px;
}

.mint .intro p {
    line-height: 1.25;
}

.mint .mint-content {
    max-width: 1350px;
    margin: auto;
    z-index: 10;
    position: relative;
}
.mint .mint-lions {
    transform: scale(1.3);
}
.mint .btn.gradient-btn {
    padding: 15px 30px 15px 20px !important;
}

.mint .btn.gradient-2 {
    padding-left: 20px;
    padding-right: 35px;
}

.mint .header-content div {
    padding-right: 0px;
    padding-left: 0px;
}

.mint .intro .btn img {
    margin-left: 15px;
}

.mint-body {
    margin: 0% 8%;
    padding: 5% 7%;
    background-color: rgb(255 255 255 / 5%);
}

.mint-body>div {
    border-bottom: 2px solid rgba(255, 255, 255, 0.7);
    padding: 2% 0;
    min-height: 70px;
    display: flex;
    align-items: center;
}

.mint-body>div:last-child {
    border-bottom: none;
    padding-top: 10%;
    display: block;
    text-align: center;
}

.mint-body>div:last-child p {
    font-size: 18px;
    opacity: 0.8;
}

.amount-input {
    display: inline-block;
}

.mint-body input {
    height: 40px !important;
    font-size: 20px !important;
}

.mint-failedMsg {
    min-height: 80vh;
    color: red;
}

.btn-max {
    background-color: #fff;
    border-radius: 3px;
    border: none;
    color: #000;
    font-weight: 900;
    font-size: 17px;
    font-family: 'Poppins' !important;
    transition: all 0.5s;
}

.btn-max:hover {
    transform: translate(0px, -3px);
    background-color: #e3e3e3;
}
.btn-mint {
    background-clip: border-box;
    padding: 4% 5%;
    border-radius: 5px !important;
    font-size: 23px;
    width: 100%;
    box-shadow: none;
    margin-bottom: 2%;
}

.btn-mint.disable {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn-mint.disable:hover {
    transform: none;
    color: #fff;
    box-shadow: none;
}

.mint-failedMsg h1 {
    font-size: 70px !important;
}

#footer.mint {
    margin-top: -35% !important;
}

.soldout-title {
    font-family: "ARMY RUST";
    font-size: 80px;
}
.opensea-btn-success {
    padding: 15px 30px;
}
@media (min-width: 1541px) {
    .mint .mint-content {
        margin-top: 5%;
    }
    
}
@media (max-width: 1541px) {
    .mint .mint-lions {
        transform: scale(1.1);
    }
}

@media (max-width: 1440px) {
    .mint .mint-content {
        max-width: 1200px;
    }
}

@media (max-width: 1300px) {
    .mint .mint-content {
        max-width: 95%;
    }
    .mintImg-div {
        padding: 0%;
        text-align: center;
    }
    .success-msg h1 {
        letter-spacing: 4px;
    }
}

@media (max-width: 1120px) {
    .mint-content .row {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
    .mint img.header-bounty-img {
        margin-left: 0%;
    }
    .soldout-title {
        font-size: 60px;
    }
}

@media (max-width: 1130px) {
    .mint .navbar-right li {
        display: inline-block !important;
    }
    .mint-content {
        overflow: hidden !important;
    }
}

@media (max-width: 1000px) {
    .mint .footer {
        margin-top: 0%;
    }
    .btn-modal {
        padding: 15px 30px;
        font-size: 15px;
    }
    .success-msg {
        margin-top: 35%;
    }
    .success-msg h1 {
        font-size: 35px;
    }
}

@media (max-width: 767px) {
    .mint .intro .row.d-flex.align-items-center.justify-center {
        display: flex !important;
        padding-right: 15px !important;
    }
    .mint .mint-content {
        max-width: 90%;
    }
    .mint .main-title {
        margin-top: 0px;
    }
    .mint .mint-lions {
        transform: scale(1);
    }
    .mint .intro img {
        max-width: 80vw;
    }
    h3 {
        font-size: 22px !important;
    }
    .mint p {
        font-size: 18px;
    }
    .row.d-flex {
        display: block;
    }
    .mint .footer {
        margin-top: 0%;
        padding-bottom: 35%;
    }
    .mint .intro {
        padding-top: 180px;
    }
    .mint .navbar-header {
        display: inline-block !important;
    }
    .mint #navbarCollapse {
        float: right !important;
    }
    .mint #navbarCollpase .nav {
        display: flex;
    }
    .mint .navbar-collapse {
        background-color: transparent !important;
        box-shadow: inset 0 0px 0 rgb(255 255 255 / 10%);
    }
    body {
        overflow-x: hidden;
    }
    .content-after {
        width: 100%;
    }
    .mint-logo img {
        width: 17vw;
    }
    .success-msg h1 {
        font-size: 40px;
    }
    .success-msg {
        margin-top: 35%;
    }
    .mintImg-div {
        padding: 0% 0 0 0%;
        text-align: center;
        margin-top: 5%;
    }
}

@media (max-width: 550px) {
    .opensea-btn-success {
        margin-right: 0px !important;
    }
    .mint .footer {
        padding-bottom: 40%;
    }
    .mint-body {
        margin: 2% 0;
    }
    .mint-logo-img {
        width: 50px;
    }
}

@media (max-width: 500px) {
    .footer-content {
        bottom: 0%;
    }
    .close-icon {
        font-size: 30px;
    }
    .soldout-title {
        font-size: 40px;
    }
}

@media (max-width: 450px) {
    .success-msg h1 {
        font-size: 30px;
        letter-spacing: 5;
    }
    .success-msg p {
        font-size: 13px;
        letter-spacing: 5;
    }
}
@media (max-width: 310px) {
    .soldout-title {
        font-size: 30px;
        word-break: break-all;
    }
}